export const config = [
  {
    name: "Biju Korassery Balakrishnan",
    rating: 5,
    time: "3 weeks ago",
    message:
      "It was a safe journey from airport to home with all covid-19 measures implemented in the taxi. Thank you sonet.",
  },
  {
    name: "prajakta bageshwar",
    rating: 5,
    time: "30 weeks ago",
    message:
      "Safe drive, awesome experience with reasonable price......thank u sonet and subin",
  },
  {
    name: "arijit sardar",
    rating: 5,
    time: "37 weeks ago",
    message:
      "Excellent and professional services. Car was well maintained and very clean. Driver was experienced. We had booked innova for 4 days and we had been to Munnar and Alleppey in Dec, 2019. Overall very good journey.",
  },
  {
    name: "vinoth kumar Deenadayalan",
    rating: 5,
    time: "38 weeks ago",
    message: "Good Service, Driver Mussanna came and dropped on time.",
  },
  {
    name: "Dhanraj Shewale",
    rating: 5,
    time: "39 weeks ago",
    message: "Osm experience... Driver was so polite...",
  },
  {
    name: "Sanjeev Aboti",
    rating: 5,
    time: "46 weeks ago",
    message:
      "Nice..we all very much enjoyed a trip. And Driver Rajiv Krishna now became our team membr family member. Thanks for your nice service sir. - Regards - Aboti",
  },
]
