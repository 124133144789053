import moment from "moment"

export const generateKeywords = () => {
  const allKeywords = [
    "rental cars kerala",
    "Car rental at kerala",
    "kerala taxi tour",
    "tempo traveller rent in kochi",
    "car rent kerala",
    "taxi services kerala",
    "kerala car rentals",
    "car on rent kerala",
    "cab services in kerala",
    "cab in kerala",
    "tempo traveller in kerala",
    "tempo traveller kerala",
    "kerala car rental",
    "kochi car rental",
    "cab service in kerala",
    "kerala tempo traveller",
    "kerala tourist taxi",
    "taxi rates in kerala",
    "taxi rate in kerala",
    "kerala taxi rate",
    "taxi kerala rates",
    "tempo traveller rent kochi",
    "taxi fares in kerala",
    "kerala taxi cabs",
    "taxi fare in kerala",
    "kerala taxi package",
    "car rentals in cochin",
    "rent car cochin",
    "car rent kochi",
    "car for rent kochi",
    "rental car kerala",
    "rent a car in cochin",
    "cars for rent in kochi",
    "rental car in kochi",
    "rental cars in kochi",
    "car for rent kerala",
    "kerela taxi",
    "cab kerala",
    "cabs kerala",
    "taxi service kerala",
    "taxi services in kerala",
    "kerala taxi service",
    "kerala taxi cab",
    "cab service kerala",
    "kerala cabs service",
    "taxi kerala tour",
    "car rental at kochi",
    "kochi car rent",
    "cochin car rentals",
    "rental cars kochi",
    "car rentals cochin",
    "car rentals kochi",
    "rent a car at kochi",
    "cochin car rental",
    "car rent in kochi",
    "car rental in kochi",
    "car rental kochi",
    "car for rent in kochi",
    "car rental at cochin",
    "car rental in cochin",
    "car rental cochin",
    "car for rent in kerala",
    "car rental at kerala",
    "car rentals kerala",
    "cochin rent a car",
    "tempo traveller for rent in kochi",
    "traveller kerala",
    "kerala taxis",
    "kerala cab service",
    "car for rent in kochi",
    "taxi package in kerala",
    "taxi packages in kerala",
    "taxi package in kerala",
    "best taxi service in kerala",
    "taxi service for kerala",
    "tour car rentals in kochi",
    "kerala cabs",
    "cabs in kerala kerala cab",
    "kerala cab services",
    "kerala taxi",
    "kerala taxi packages",
    "taxi service in cochin",
  ]

  const selectedKeywords = []

  for (let index = 0; index < 4; index++) {
    const element = allKeywords[moment().week() + index * 10]
    selectedKeywords.push(element)
  }

  return selectedKeywords.join(", ")
}
