export const config = {
  navLinks: [
    {
      link: `About`,
      to: `#About`,
    },
    {
      link: `Fleet`,
      to: `#Fleet`,
    },
    {
      link: `Contact`,
      to: `#Contact`,
    },
  ],
}
